import { Suspense } from "react";
import { Navigate, Outlet, useLocation, ScrollRestoration } from "react-router-dom";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { RoutePaths } from "src/pages/routePaths";
import { CgSpinner } from "react-icons/cg";
interface AuthRouteProps {
  userRole: string;
}

const AuthRoute = () => {
  const location = useLocation();
  const { isLoading, user } = useCurrentUser();

  if (isLoading) {
    return (
      <div className="flex h-screen w-full items-center justify-center py-48">
        <CgSpinner className="text-primary-400 size-20 animate-spin" />
      </div>
    );
  }

  if (location.pathname.includes(RoutePaths.Message) && !user?.id) {
    return <Navigate to={RoutePaths.Landing} replace />;
  }

  if (!user || user.status === "CHANGED") {
    return <Navigate to={RoutePaths.Landing} replace />;
  }

  return (
    <Suspense>
      <Outlet />
      <ScrollRestoration />
    </Suspense>
  );
};

export default AuthRoute;
