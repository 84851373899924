export const __PROD__ = process.env.REACT_APP_ENV === "production";

export const constants = {
  COMPANY_LOGO_PATH: "/handyy-logo.svg",
  COMPANY_LOGO_INVERSE_PATH: "/handyy-inverse.svg",
  ACCESS_TOKEN_STORAGE_KEY: "handyy-access-token",
  REFRESH_TOKEN_STORAGE_KEY: "handyy-refresh-token",
  GUEST_ADDRESS_SESSION_KEY: "guest-session-address",
  OTP_COOLDOWN_SECONDS: 60,
  DOWNLOAD_APPLE: "https://apps.apple.com/app",
  DOWNLOAD_GOOGLE: "https://play.google.com/store/apps"
  
};

export const appConfig = {
  termConditionUrl: __PROD__
    ? "https://storage.cloud.google.com/handyy-production-bucket/document/Handyy_Partner%20Terms%20of%20Use.pdf"
    : "https://storage.googleapis.com/handyy-bucket/document/Handyy_Partner%20Terms%20of%20Use.pdf",
};

export const GENERAL_CLEANING_ID = 48;
