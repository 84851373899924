import platform from "platform";

export const getDeviceInfo = () => ({
  deviceType:
    platform.description?.includes("Mobile") || platform.description?.includes("Tablet")
      ? "Mobile"
      : "Web",
  deviceModel: platform.product || "None",
  os: platform.os?.toString() || "None",
  browser: platform.name || "None",
});
