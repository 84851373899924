import { useEffect } from "react";

const updateFavicon = (isDarkMode: boolean) => {
  const favicon = document.getElementById("favicon") as HTMLLinkElement;
  if (favicon) {
    favicon.href = isDarkMode ? "/favicon-invers.ico" : "/favicon.ico";
  }
};

const useChangeFavicon = () => {
  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    updateFavicon(mediaQuery.matches);
    const listener = (event: MediaQueryListEvent) => {
      updateFavicon(event.matches);
    };

    mediaQuery.addEventListener("change", listener);

    return () => mediaQuery.removeEventListener("change", listener);
  }, []);
};

export default useChangeFavicon;
